// export const myGetter = ( state ) => {
//   return state.algo
// }

export const getEmpresas= ( state ) => {
  return state.empresas
}

export const getEmpresaById = ( state ) => ( id = '' ) => {
  const empresa = state.empresas.find(c => c._id === id)
  if (!empresa) return
  return empresa
}

export const getEmpresasNameValue = ( state ) => {
  const nuevoArray = state.empresas.map((objeto) => {
    return Object.keys(objeto).reduce((resultado, key) => {
      if (key === "name" || key === "_id") {
        resultado[key] = objeto[key];
      }
      return resultado;
    }, {});
  });

  const ArrayFormated = nuevoArray.map( empresa => {
    return {
      label : empresa.name,
      value : empresa._id
    }
  }) 

  return ArrayFormated
}
export const getStatusCode = ( state ) => {
  return state.statusCode
}