import laroaccessApi from "@/api/laroaccessApi"

export const loadGrupos = async ({ commit }) => {
  try {
    const { status, data }= await laroaccessApi.get('/access-group',{
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    if ( !data ) {
      commit('setGrupos', [])
      return
    }
    const grupos = []
    //Transformar un objeto literal en un array de objetos
    for (let id of Object.keys( data )) {
      grupos.push({
        ...data[id]
      })
    }
    commit('setGrupos', grupos)
    commit('setStatusCode', {code:status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}
export const createGrupo = async ({ commit }, grupo) => {
  try {
    const {name, sub_company, device } = grupo
    let arrayDevicesFormated = device.join(',')
    const dataToSave = { name, sub_company, device:arrayDevicesFormated }
    const { status } = await laroaccessApi.post('/access-group', dataToSave, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('setStatusCode', {code:status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}
export const updateGrupo = async ({ commit }, data) => {
  const {id, value} = data
  try {
    const { name, sub_company, device } = value
    let arrayDevicesFormated = device.join(',')
    const dataToSave = { access_group_id:id, name, sub_company, device:arrayDevicesFormated }
    const { status } = await laroaccessApi.patch(`/access-group/${id}`, dataToSave, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('setStatusCode', {code: status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}

export const deleteGrupo = async ({ commit }, idGrupo) => {
  try {
    await laroaccessApi.delete(`/access-group/${idGrupo}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('deleteGrupo', idGrupo)
    
  } catch (error) {
    console.log(error)
  }
}
