import laroaccessApi from "@/api/laroaccessApi"

export const loadEmpleados= async ({ commit }) => {
  try {
    const { status, data }= await laroaccessApi.get('/employee', {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    if ( !data ) {
      commit('setEmpleados', [])
      commit('setStatusCode', status)
      return
    }
    const empleados = []
    //Transformar un objeto literal en un array de objetos
    for (let id of Object.keys( data )) {
      empleados.push({
        id,
        ...data[id]
      })
    }
    commit('setEmpleados', empleados)
    commit('setStatusCode', {code:status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}

export const createEmpleado = async ({ commit }, empleado) => {
  try {
    const { 
      employee_id, 
      dni_type, 
      dni, 
      first_name, 
      second_name,
      first_lastname,
      second_lastname,
      email,
      phone,
      categories,
      campus,
      contract_start_date,
      contract_end_date,
      city,
      barcode,
      rfid,
      profile_picture,
      access_group
    } = empleado
    let arrayCategoriesFormated = categories.join(',')
    let arrayAccessGroupFormated = access_group.join(',')
    // Formateo para poder guardar la foto de una vez en el post
    let profilePictureFormated
    
    if (profile_picture) {
      profilePictureFormated = profile_picture._id
    } else {
      profilePictureFormated = null
    }

    const dataToSave = { 
      employee_id, 
      dni_type, 
      dni, 
      first_name, 
      second_name,
      first_lastname,
      second_lastname,
      email,
      phone,
      categories: arrayCategoriesFormated,
      campus,
      contract_start_date,
      contract_end_date,
      city,
      barcode,
      rfid,
      profile_picture: profilePictureFormated,
      access_group: arrayAccessGroupFormated
     }
    const { status } = await laroaccessApi.post('/employee', dataToSave, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('setStatusCode', {code: status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}
export const updateEmpleado = async ({ commit }, data) => {
  const {id, value} = data
  try {
    const {
      is_active,
      employee_id, 
      dni_type, 
      dni, 
      first_name, 
      second_name,
      first_lastname,
      second_lastname,
      email,
      phone,
      categories,
      campus,
      contract_start_date,
      contract_end_date,
      city,
      barcode,
      rfid,
      profile_picture,
      access_group
    } = value
    let isActiveToSring = is_active.toString()
    let arrayCategoriesFormated = categories.join(',')
    let arrayAccessGroupFormated = access_group.join(',')
    let profilePictureFormated
    if (profile_picture) {
      profilePictureFormated = profile_picture._id
    } else {
      profilePictureFormated = null
    }

    const dataToSave = { 
      is_active:isActiveToSring,
      employee_id, 
      dni_type, 
      dni, 
      first_name, 
      second_name,
      first_lastname,
      second_lastname,
      email,
      phone,
      categories: arrayCategoriesFormated,
      campus,
      contract_start_date,
      contract_end_date,
      city,
      barcode,
      rfid,
      profile_picture: profilePictureFormated,
      access_group: arrayAccessGroupFormated,
    }

    const { status } = await laroaccessApi.patch(`/employee/${id}`, dataToSave, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('setStatusCode', {code: status})
  } catch (error) {
    console.log(error)
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}

export const deleteEmpleado = async ({ commit }, idEmpleado) => {
  try {
    await laroaccessApi.delete(`/employee/${idEmpleado}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('deleteEmpleado', idEmpleado)
    
  } catch (error) {
    console.log(error)
  }
}