import laroaccessApi from "@/api/laroaccessApi"


export const loadVisitantes = async ({ commit }) => {
  const { data }= await laroaccessApi.get('/employee')
  if ( !data ) {
    commit('setVisitantes', [])
    return
  }
  const visitantes = []
  //Transformar un objeto literal en un array de objetos
  for (let id of Object.keys( data )) {
    visitantes.push({
      id,
      ...data[id]
    })
  }

  commit('setVisitantes', visitantes)
}