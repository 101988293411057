import laroaccessApi from "@/api/laroaccessApi"

export const loadVehicles= async ({ commit }) => {
  try {
    const { status, data }= await laroaccessApi.get('/vehicle',{
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    if ( !data ) {
      commit('setVehicles', [])
      commit('setStatusCode', {code:status})
      return
    }
    const vehicles = []
    //Transformar un objeto literal en un array de objetos
    for (let id of Object.keys( data )) {
      vehicles.push({
        ...data[id]
      })
    }
    commit('setVehicles', vehicles)
    commit('setStatusCode', {code:status})
  } catch (error) {
    commit('setStatusCode', {errorCode: error.response.data.statusCode, message:error.response.data.message})
  }
}
export const createVehicle = async ({ commit }, vehicle) => {
  try {
    const {
      type,
      plate,
      model,
      color,
      description,
      fabricator,
      barcode,
      profile_picture,
      access_group,
      contract_start_date,
      contract_end_date
    } = vehicle

    let arrayAccessGroupFormated = access_group.join(',')
    let profilePictureFormated
    
    if (profile_picture) {
      profilePictureFormated = profile_picture._id
    } else {
      profilePictureFormated = null
    }

    const dataToSave = { 
      type,
      plate,
      model,
      color,
      description,
      fabricator,
      barcode,
      contract_start_date,
      contract_end_date,
      profile_picture: profilePictureFormated,
      access_group: arrayAccessGroupFormated
    }

    const { status } = await laroaccessApi.post('/vehicle', dataToSave, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('setStatusCode', {code: status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}
export const updateVehicle = async ({ commit }, data) => {
  const {id, value} = data
  try {
    const {
      is_active,
      type,
      plate,
      model,
      color,
      description,
      fabricator,
      barcode,
      profile_picture,
      access_group,
      contract_start_date,
      contract_end_date,
     } = value
    let isActiveToSring = is_active.toString()
    let arrayAccessGroupFormated = access_group.join(',')
    let profilePictureFormated

    if (profile_picture) {
      profilePictureFormated = profile_picture._id
    } else {
      profilePictureFormated = null
    }

    const dataToSave = { 
      is_active:isActiveToSring,
      type,
      plate,
      model,
      color,
      description,
      fabricator,
      barcode,
      contract_start_date,
      contract_end_date,
      profile_picture: profilePictureFormated,
      access_group: arrayAccessGroupFormated
     }
    const { status } = await laroaccessApi.patch(`/vehicle/${id}`, dataToSave, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('setStatusCode', {code: status})
    
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}

export const deleteVehicle = async ({ commit }, idVehicle) => {
  try {
    await laroaccessApi.delete(`/vehicle/${idVehicle}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('deleteVehicle', idVehicle)
    
  } catch (error) {
    console.log(error)
  }
}
