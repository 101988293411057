// export const myMutation = ( state ) => {

// }

export const setGrupos = ( state, grupos ) => {
  // state.marcajes = [...marcajes]
  state.grupos = [...grupos]
  state.isLoading = false
}
export const addGrupo = ( state, grupo ) => {
  state.grupos = [grupo,...state.grupos]
  state.isLoading = false
}
export const deleteGrupo = ( state, id ) => {
  state.grupos = state.grupos.filter( e => e._id !== id)
}
export const setStatusCode = ( state, status) => {
  state.statusCode = status
}