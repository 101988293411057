// export const myGetter = ( state ) => {
//   return state.algo
// }

export const getGrupos = ( state ) => {
  return state.grupos
}
export const getGruposWithDevices = ( state ) => {
  const array = state.grupos.map(grupo => ({
    ...grupo,
    device: grupo.device.map(device => device.name).join(', ')
  }))
  return array
}
export const getGrupoById = ( state ) => ( id = '' ) => {
  const grupo = state.grupos.find(c => c._id === id)
  if (!grupo) return
  return grupo
}

export const getGruposNameValue = ( state ) => {
  const nuevoArray = state.grupos.map((objeto) => {
    return Object.keys(objeto).reduce((resultado, key) => {
      if (key === "name" || key === "_id") {
        resultado[key] = objeto[key];
      }
      return resultado;
    }, {});
  });

  const ArrayFormated = nuevoArray.map( grupo => {
    return {
      label : grupo.name,
      value : grupo._id
    }
  }) 

  return ArrayFormated
}
export const getStatusCode = ( state ) => {
  return state.statusCode
}