<template>
  <nav class="navbar navbar-expand-lg border-bottom">
    <div class="container-fluid align-items-center">
      <a class="navbar-brand" href="#">
        <img :src="require('@/assets/images/login/logo_cotema.png')" width="200">
      </a>
        <span
        class="sidebar-burger mb-0 d-auto d-sm-none"
        @click="toggleSidebar"
      ><i class="bi bi-gear fs-1"></i>
      </span>
      <div class="row align-items-center flex-wrap">
        <div class="col-auto" v-if="token.role.name === 'superadmin'">
          <ul class="navbar-nav justify-content-sm-end ms-2 nav-toggle">
            <li class="nav-item dropdown ">
              <a class="nav-link dropdown-toggle fw-semibold toggle-navbar-menu " href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-gear fs-1"></i>
              </a>
              <ul class="dropdown-menu border-0 shadow">
                <li><router-link class="dropdown-item" :to="{ name: 'home-empresas' }">Empresas</router-link></li>
                <li><router-link class="dropdown-item" :to="{ name: 'home-categorias' }">Categorias</router-link></li>
                <li><router-link class="dropdown-item" :to="{ name: 'home-terminales' }">Terminales</router-link></li>
                <li><router-link class="dropdown-item" :to="{ name: 'home-grupos' }">Grupos</router-link></li>
                <li><router-link class="dropdown-item" :to="{ name: 'home-sedes' }">Sedes</router-link></li>
                <li><router-link class="dropdown-item" :to="{ name: 'home-zonas' }">Zonas</router-link></li>
                <!-- <li><router-link class="dropdown-item" :to="{ name: 'home-usuarios' }">Usuarios</router-link></li> -->
              </ul>
            </li>
          </ul>
        </div>
        <div class="col-sm-6">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle toggle-navbar-menu fw-semibold d-flex align-items-center" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <div class="circle">
                  <img src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg" alt="profile">
                </div>
                <span v-if="token.username">{{token.username}}</span>
              </a>
              <ul class="dropdown-menu border-0 shadow">
                <!-- <li><router-link class="dropdown-item" :to="{ name: 'home-perfil' }">Mi Perfil</router-link></li> -->
                <li><a type="button" class="dropdown-item" @click="logout">Log out</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <nav class="navbar navbar-expand-lg bg-body-tertiary shadow-sm">
    <div class="container-fluid">
        <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button> -->
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item me-3">
            <div class="dropdown">
              <button class="nav-link px-3 btn-nav-link border-0 dropdown-toggle" :class="{ active: isDashboardRoute }" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Dashboard
              </button>
              <ul class="dropdown-menu">
                <li><router-link :to="{name: 'dashboard'}" class="dropdown-item">Dashboard principal</router-link></li>
                <li><router-link :to="{name: 'dashboard-vehiculos'}" class="dropdown-item">Dashboard vehiculos</router-link></li>
                <!-- <li><router-link :to="{name: 'dashboard-visitantes'}" class="dropdown-item">Dashboard visitantes</router-link></li> -->
              </ul>
            </div>
          </li>
          <li class="nav-item me-3" v-if="(token.role.name === 'superadmin') || (token.role.name === 'administrator') || (token.role.name === 'coordinator')">
            <router-link :to="{name: 'empleados'}" class="nav-link px-3 btn-nav-link border-0">Empleados</router-link>
          </li>
          <!-- <li class="nav-item me-3">
            <router-link :to="{name: 'home-visitantes'}" class="nav-link px-3 btn-nav-link border-0">Visitantes</router-link>
          </li> -->
          <li class="nav-item me-3" v-if="(token.role.name === 'superadmin') || (token.role.name === 'administrator')">
            <router-link :to="{name: 'home-vehiculos'}" class="nav-link px-3 btn-nav-link border-0">Vehiculos</router-link>
          </li>
          <li class="nav-item me-3" v-if="(token.role.name === 'superadmin') || (token.role.name === 'administrator') || (token.role.name === 'coordinator')">
            <router-link :to="{name: 'home-marcajes'}" class="nav-link px-3 btn-nav-link border-0">Marcajes</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex';
export default {
  methods: {
    toggleSidebar() {
      document.body.classList.toggle("open");
    },
    logout() {
      window.sessionStorage.removeItem('_token');
      this.$router.push({name: 'login'})
    }
  },
  computed: {
    isDashboardRoute() {
      return this.$route.name.startsWith('dashboard');
    },
    token() {
      return JSON.parse(sessionStorage.getItem("_token"))
    }
  }
};
</script>

<style lang="scss">

.btn-nav-link {
  border-radius: 12px;
  padding: 10px 0;
}
.btn-nav-link:hover {
  background-color: rgba(231, 231, 231, 0.623);
}
.btn-nav-link.router-link-exact-active, .btn-nav-link.active {
  background: #1F1C53;
  font-weight: 700;
  color: #fff !important;
}

.circle{
   width: 60px;
   height: 60px;
   border-radius: 50%;
   overflow: hidden;
}
.circle img{
   width: 100%;
   height: 100%;
   object-fit: cover;
}
@media (max-width: 576px) {
  .nav-toggle {
    display: none !important;
  }
}
</style>