import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.scss'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import VueApexCharts from "vue3-apexcharts";
import { plugin, defaultConfig } from '@formkit/vue';
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import '@/assets/css/index.scss'
import store from './store'
createApp(App)
  .use(router)
  .use(VueApexCharts)
  .use(VueSweetalert2)
  .use( store )
  .use(plugin, defaultConfig({
      theme: 'genesis'
  
  }))
  .component(
    'EasyDataTable', Vue3EasyDataTable
  )
  .mount('#app')
