import { createStore } from 'vuex'
import empleados from "@/modules/empleados/store/empleados"
import visitantes from "@/modules/visitantes/store/visitantes"
import login from "@/modules/login/store/login"
import marcajes from "@/modules/dashboard/store/marcajes"
import empresas from "@/modules/empresas/store/empresas"
import categorias from "@/modules/categorias/store/categorias"
import devices from "@/modules/terminales/store/terminales"
import sedes from "@/modules/sedes/store/sedes"
import vehicles from "@/modules/vehiculos/store/vehiculos"
import grupos from "@/modules/grupos/store/grupos"
import zonas from "@/modules/zonas/store/zonas"

const store = createStore({
  modules: {
    empleados,
    login,
    visitantes,
    marcajes,
    empresas,
    categorias,
    devices,
    sedes,
    vehicles,
    grupos,
    zonas
  }
})

export default store