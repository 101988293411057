// export const myGetter = ( state ) => {
//   return state.algo
// }

export const getDevices = ( state ) => {
  return state.devices
}
export const getDevicesLength = ( state ) => {
  return state.devices.length
}
export const getDevicesOnline = ( state ) => {
  const arrayFormateado = state.devices.filter( device => device.is_online )
  return arrayFormateado.length
}
export const getDevicesOffline = ( state ) => {
  const arrayFormateado = state.devices.filter( device => !device.is_online )
  return arrayFormateado.length
}
export const getDevicesNameValue = ( state ) => {
  const nuevoArray = state.devices.map((objeto) => {
    return Object.keys(objeto).reduce((resultado, key) => {
      if (key === "name" || key === "_id") {
        resultado[key] = objeto[key];
      }
      return resultado;
    }, {});
  });

  const ArrayFormated = nuevoArray.map( device => {
    return {
      label : device.name,
      value : device._id
    }
  }) 

  return ArrayFormated
}
export const getDeviceById = ( state ) => ( id = '' ) => {
  const device = state.devices.find(c => c._id === id)
  if (!device) return
  return device
}
export const getStatusCode = ( state ) => {
  return state.statusCode
}
