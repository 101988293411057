import laroaccessApi from "@/api/laroaccessApi"
import "moment-timezone"
import moment from "moment"

export const loadMarcajes= async ({ commit }) => {
  // Obtener la fecha y hora actual
  const now = moment.tz('America/Bogota');

  // Ajustar las fechas de inicio y fin para reflejar el día actual en Colombia
  const start_date = now.clone().subtract(1, 'day').format('YYYY-MM-DD');
  // const end_date = now.clone().add(1, 'day').format('YYYY-MM-DD');
  const end_date = moment().add(1, 'd').utc().format('YYYY-MM-DD');
  const { data }= await laroaccessApi.get(`/authentication-record?start_date=${start_date}&end_date=${end_date}`, {
    headers: {
      Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
    },
  })
  if ( !data ) {
    commit('setMarcajes', [])
    return
  }
  const marcajes= []
  //Transformar un objeto literal en un array de objetos
  for (let id of Object.keys( data)) {
    marcajes.push({
      id,
      ...data[id]
    })
  }
  commit('setMarcajes', marcajes)
}
export const loadMarcajesAsistencia = async ({ commit }) => {
  const { data }= await laroaccessApi.get('/attendance', {
    headers: {
      Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
    }
  })
  if ( !data ) {
    commit('setMarcajesAsistencia', [])
    return
  }
  const marcajesAsistencia = []
  for (let id of Object.keys( data )) {
    marcajesAsistencia.push({
      id,
      ...data[id]
    })
  }
  commit('setMarcajesAsistencia', marcajesAsistencia)
}

