// export const myMutation = ( state ) => {

// }

export const setVehicles = ( state, vehicles) => {
  // state.marcajes = [...marcajes]
  state.vehicles = [...vehicles]
  state.isLoading = false
}
export const addVehicle = ( state, vehicle ) => {
  state.vehicles = [vehicle,...state.vehicles]
  state.isLoading = false
}
export const deleteVehicle = ( state, id ) => {
  state.vehicles = state.vehicles.filter( e => e._id !== id)
}
export const setStatusCode = ( state, status) => {
  state.statusCode = status
}