import laroaccessApi from "@/api/laroaccessApi"


export const loadEmpresas= async ({ commit }) => {
  try {
    const { status, data }= await laroaccessApi.get('/sub-company', {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    if ( !data ) {
      commit('setEmpresas', [])
      return
    }
    const empresas = []
    //Transformar un objeto literal en un array de objetos
    for (let id of Object.keys( data )) {
      empresas.push({
        id,
        ...data[id]
      })
    }
    commit('setEmpresas', empresas)
    commit('setStatusCode', {code:status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}
export const createEmpresa = async ({ commit }, empresa) => {
  try {
    const {name, city, company } = empresa
    const dataToSave = { name, city, company}
    const { status } = await laroaccessApi.post('/sub-company', dataToSave, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('setStatusCode', {code:status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}

export const updateEmpresa = async ({ commit }, data) => {
  const {id, value} = data
  try {
    const {name, city, company } = value
    const dataToSave = { name, city, company }
    const { status } = await laroaccessApi.patch(`/sub-company/${id}`, dataToSave, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('setStatusCode', {code: status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}

export const deleteEmpresa= async ({ commit }, idEmpresa) => {
  try {
    await laroaccessApi.delete(`/sub-company/${idEmpresa}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('deleteEmpresa', idEmpresa)
    
  } catch (error) {
    console.log(error)
  }
}