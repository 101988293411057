import laroaccessApi from "@/api/laroaccessApi"

export const getToken = async ({ commit }, dataSubmit) => {
  const { username, password } = dataSubmit
  try {
    const { status, data }= await laroaccessApi.post('/auth',{
      username,
      password
    })
    
    window.sessionStorage.setItem('_token', JSON.stringify(data));
    commit('setToken', data)
    commit('setStatusCode', status)
  } catch (error) {
    commit('setStatusCode', error.response.data.statusCode)
    // console.log('error:', error.response.data)
  }
}

export const verifyToken = async ({ commit }, token) => {
  try {
    const { data }= await laroaccessApi.post('/auth/verify-token',{
      token
    })
    window.sessionStorage.setItem('_token', JSON.stringify(data));
  } catch (error) {
    console.log(error)
  }
}
