// export const myMutation = ( state ) => {

// }

export const setToken = ( state, token ) => {
  state.token = token
  state.isLoading = false
}
export const setStatusCode = ( state, status) => {
  state.statusCode = status
}