// export const myGetter = ( state ) => {
//   return state.algo
// }

export const getMarcajes = (state) => {
  return state.marcajes;
};


export const getDataForChartVehicles = (state) => {
  const registrosPorDevice = state.marcajesAsistencia.reduce((acc, item) => {
    if (item.entity === 'vehicle') {
      const { name } = item.device;
      acc[name] = acc[name] ? acc[name] + 1 : 1;
    }
    return acc;
  }, {});
  if(registrosPorDevice.length <= 0) {
    return []
  }
  const resultado = Object.entries(registrosPorDevice).map(([name, registros]) => ({ name, registros }));
  
  return resultado
};
export const getDataForChartEmployee = (state) => {
  const registrosPorDevice = state.marcajesAsistencia.reduce((acc, item) => {
    if (item.entity === 'employee' &&  item.employee?.categories?.[0] ) {
      const { name } = item.device;
      acc[name] = acc[name] ? acc[name] + 1 : 1;
    }
    return acc;
  }, {});
  if(registrosPorDevice.length <= 0) {
    return []
  }
  const resultado = Object.entries(registrosPorDevice).map(([name, registros]) => ({ name, registros }));
  return resultado
};
export const getDataForChartEmployeeAsist = (state) => {
  const registrosPorDevice = state.marcajesAsistencia.reduce((acc, item) => {
    if (item.entity === 'employee' &&  item.employee?.categories?.[0] ) {
      const categoria = item.employee?.categories?.[0]?.name;
      acc[categoria] = acc[categoria] ? acc[categoria] + 1 : 1;
    }
    return acc;
  }, {});
  if(registrosPorDevice.length <= 0) {
    return []
  }
  const resultado = Object.entries(registrosPorDevice).map(([name, registros]) => ({ name, registros }));
  return resultado
};
// export const getDataForChartEmployeeTest = (state) => {
//   const registrosPorDispositivoYCategoria = {};

//   state.marcajesAsistencia.forEach((item) => {
//     if (item.entity === 'employee') {
//       const dispositivo = item.device.name;
//       const categoria = item.employee?.categories?.[0]?.name || 'Sin categoría';

//       if (!registrosPorDispositivoYCategoria[categoria]) {
//         registrosPorDispositivoYCategoria[categoria] = {};
//       }

//       if (!registrosPorDispositivoYCategoria[categoria][dispositivo]) {
//         registrosPorDispositivoYCategoria[categoria][dispositivo] = 0;
//       }

//       registrosPorDispositivoYCategoria[categoria][dispositivo]++;
//     }
//   });

//   if (Object.keys(registrosPorDispositivoYCategoria).length === 0) {
//     return [];
//   }

//   const data = Object.keys(registrosPorDispositivoYCategoria).map((categoria) => {
//     const devices = Object.keys(registrosPorDispositivoYCategoria[categoria]).map((device) => {
//       return {
//         name: device,
//         registros: registrosPorDispositivoYCategoria[categoria][device]
//       }
//     });

//     return {
//       cateogorie_name: categoria,
//       devices: devices
//     }
//   });

//   return data;
// };
export const getMarcajesVehiculos = (state) => {
  // Nuevo array filtrado

  const arrayFiltrado = state.marcajes.filter((m) => m.entity === "vehicle" && m.hasOwnProperty("vehicle"));
 
  if(arrayFiltrado.length <= 0) {
    return []
  }
  const arrayProcesado = arrayFiltrado.map((item) => ({
    tipo: item.vehicle?.type,
    placa: item.vehicle?.plate,
    modelo: item.vehicle?.model,
    color: item.vehicle?.color,
    descripcion: item.vehicle?.description,
    fecha: item.createdAt,
    terminal: item.device.name,
    sentido: item.device.direction.name,
    zona: item.device.zone,
    message: item.message
  }));
  return arrayProcesado;
};
export const getMarcajesVehiculosAsistencia = (state) => {
  // Nuevo array filtrado

  const arrayFiltrado = state.marcajesAsistencia.filter((m) => m.entity === "vehicle" && m.hasOwnProperty("vehicle"));
 
  if(arrayFiltrado.length <= 0) {
    return []
  }
  const arrayProcesado = arrayFiltrado.map((item) => ({
    _id: item._id,
    tipo: item.vehicle?.type,
    placa: item.vehicle?.plate,
    modelo: item.vehicle?.model?.toString() || 'Sin modelo',
    color: item.vehicle?.color || 'Sin color',
    descripcion: item.vehicle?.description,
    fecha: item.createdAt,
    zona: item.zone?.name
  }));
  return arrayProcesado;
};
export const getMarcajesEmpleadosAsistencia = (state) => {
  // Nuevo array filtrado
  const arrayFiltrado = state.marcajesAsistencia.filter((m) => m.entity === "employee" && m.hasOwnProperty("employee"))
  if(arrayFiltrado.length <= 0) {
    return []
  }
  const arrayProcesado = arrayFiltrado.map((item) => ({
    _id: item._id,
    dni: item.employee?.dni,
    nombre: item.employee?.first_name,
    apellido: item.employee?.first_lastname,
    empresa: item.employee?.campus.sub_company.name,
    categorias: item.employee?.categories
      .map((categoria) => categoria.name)
      .join(", "),
    fecha: item.createdAt,
    zona: item.zone?.name
  }));
  return arrayProcesado;
};
export const getMarcajesEmpleados = (state) => {
  // Nuevo array filtrado
  const arrayFiltrado = state.marcajes.filter((m) => m.entity === "employee" && m.hasOwnProperty("employee"))
  if(arrayFiltrado.length <= 0) {
    return []
  }
  const arrayProcesado = arrayFiltrado.map((item) => ({
    dni: item.employee?.dni,
    nombre: item.employee?.first_name,
    apellido: item.employee?.first_lastname,
    empresa: item.employee?.campus.sub_company.name,
    categorias: item.employee?.categories
      .map((categoria) => categoria.name)
      .join(", "),
    fecha: item.createdAt,
    terminal: item.device.name,
    sentido: item.device.direction.name,
    zona: item.device.zone,
    message: item.message
  }));
  return arrayProcesado;
};
export const getCountByZone = (state) => {
    // Objeto original
  const arrayFiltrado = state.marcajesAsistencia.filter((m) => m.entity === "employee" && m.hasOwnProperty("employee"))
  if(arrayFiltrado.length <= 0) {
    return []
  }

  // Recorrer el objeto y contar las zonas
  const countByZone = {};
  for (const key in arrayFiltrado) {
    if (arrayFiltrado.hasOwnProperty(key)) {
      const element = arrayFiltrado[key];
      const zoneName = element.zone?element.zone.name:'General';
      if (countByZone.hasOwnProperty(zoneName)) {
        countByZone[zoneName]++;
      } else {
        countByZone[zoneName] = 1;
      }
    }
  }

  // Crear el objeto de resultado con el conteo por zona
  const result = Object.entries(countByZone).map(([zone, count]) => ({
    zone,
    count
  }));

  return result

}
export const getCountByZoneVehicles = (state) => {
    // Objeto original
  const arrayFiltrado = state.marcajesAsistencia.filter((m) => m.entity === "vehicle" && m.hasOwnProperty("vehicle"))
  if(arrayFiltrado.length <= 0) {
    return []
  }

  // Recorrer el objeto y contar las zonas
  const countByZone = {};
  for (const key in arrayFiltrado) {
    if (arrayFiltrado.hasOwnProperty(key)) {
      const element = arrayFiltrado[key];
      const zoneName = element.zone?element.zone.name:'General';
      if (countByZone.hasOwnProperty(zoneName)) {
        countByZone[zoneName]++;
      } else {
        countByZone[zoneName] = 1;
      }
    }
  }

  // Crear el objeto de resultado con el conteo por zona
  const result = Object.entries(countByZone).map(([zone, count]) => ({
    zone,
    count
  }));

  return result

}
