import laroaccessApi from "@/api/laroaccessApi"

export const loadZonas = async ({ commit }) => {
  try {
    const { status, data }= await laroaccessApi.get('/zone',{
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    if ( !data ) {
      commit('setZonas', [])
      return
    }
    const zonas = []
    //Transformar un objeto literal en un array de objetos
    for (let id of Object.keys( data )) {
      zonas.push({
        ...data[id]
      })
    }
    commit('setZonas', zonas)
    commit('setStatusCode', {code:status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}
export const createZona = async ({ commit }, zona) => {
  try {
    const {
      name, 
      antipassback 
    } = zona
    let antipassbackString = antipassback.toString()
    const dataToSave = { 
      name, 
      antipassback:antipassbackString
    }

    const { status } = await laroaccessApi.post('/zone', dataToSave, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('setStatusCode', {code:status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}
export const updateZona = async ({ commit }, data) => {
  const {id, value} = data
  try {
    const {name, antipassback } = value
    let antipassbackString = antipassback.toString()
    const dataToSave = { 
      name, 
      antipassback:antipassbackString
    }
    const { status } = await laroaccessApi.patch(`/zone/${id}`, dataToSave, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('setStatusCode', {code: status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}

export const deleteZona = async ({ commit }, idZona) => {
  try {
    await laroaccessApi.delete(`/zone/${idZona}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('deleteZona', idZona)
  } catch (error) {
    console.log(error)
  }
}
