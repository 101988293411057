// export const myMutation = ( state ) => {

// }

export const setDevices = ( state, devices) => {
  state.devices = [...devices]
  state.isLoading = false
}
export const addDevice = ( state, device) => {
  state.devices = [device,...state.devices]
  state.isLoading = false
}
export const deleteDevice = ( state, id ) => {
  state.devices = state.devices.filter( e => e._id !== id)
}
export const setStatusCode = ( state, status) => {
  state.statusCode = status
}