// export const myMutation = ( state ) => {

// }

export const setZonas = ( state, zonas ) => {
  // state.marcajes = [...marcajes]
  state.zonas = [...zonas]
  state.isLoading = false
}
export const addZona = ( state, zona ) => {
  state.zonas = [zona,...state.zonas]
  state.isLoading = false
}
export const deleteZona = ( state, id ) => {
  state.zonas = state.zonas.filter( e => e._id !== id)
}
export const setStatusCode = ( state, status) => {
  state.statusCode = status
}