// export const myMutation = ( state ) => {

// }

export const setEmpleados = ( state, empleados ) => {
  // state.empleados = [...empleados]
  state.empleados = [...empleados]
  state.isLoading = false
}
export const addEmpleado = ( state, empleado ) => {
  state.empleados = [empleado,...state.empleados]
  state.isLoading = false
}
export const deleteEmpleado = ( state, id ) => {
  state.empleados = state.empleados.filter( e => e._id !== id)
}
export const setStatusCode = ( state, status) => {
  state.statusCode = status
}