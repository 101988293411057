import laroaccessApi from "@/api/laroaccessApi"


export const loadSedes= async ({ commit }) => {
  try {
    const { status, data }= await laroaccessApi.get('/campus', {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    if ( !data ) {
      commit('setSedes', [])
      return
    }
    const sedes = []
    //Transformar un objeto literal en un array de objetos
    for (let id of Object.keys( data )) {
      sedes.push({
        id,
        ...data[id]
      })
    }
    commit('setSedes', sedes)
    commit('setStatusCode', {code:status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}
export const createSede = async ({ commit }, sede) => {
  try {
    const { name, sub_company } = sede
    const dataToSave = { name, sub_company }
    const { status } = await laroaccessApi.post('/campus', dataToSave, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('setStatusCode', {code:status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}

export const updateSede = async ({ commit }, data) => {
  const {id, value} = data
  try {
    const { name, sub_company } = value
    const dataToSave = { name, sub_company }
    const { status } = await laroaccessApi.patch(`/campus/${id}`, dataToSave, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('setStatusCode', {code: status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}

export const deleteSede = async ({ commit }, idSede) => {
  try {
    await laroaccessApi.delete(`/campus/${idSede}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('deleteSede', idSede)
    
  } catch (error) {
    console.log(error)
  }
}