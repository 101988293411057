// export const myMutation = ( state ) => {

// }

export const setMarcajes = ( state, marcajes ) => {
  state.marcajes = [...marcajes]
  state.isLoadingMarcajes = false
}
export const setMarcajesAsistencia = ( state, marcajesAsistencia ) => {
  state.marcajesAsistencia = [...marcajesAsistencia]
  state.isLoading = false
}

