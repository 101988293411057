// export const myGetter = ( state ) => {
//   return state.algo
// }

export const getZonas = ( state ) => {
  return state.zonas
}

export const getZonaById = ( state ) => ( id = '' ) => {
  const zona = state.zonas.find(c => c._id === id)
  if (!zona) return
  return zona
}

export const getZonasNameValue = ( state ) => {
  const nuevoArray = state.zonas.map((objeto) => {
    return Object.keys(objeto).reduce((resultado, key) => {
      if (key === "name" || key === "_id") {
        resultado[key] = objeto[key];
      }
      return resultado;
    }, {});
  });

  const ArrayFormated = nuevoArray.map( zona => {
    return {
      label : zona.name,
      value : zona._id
    }
  }) 

  return ArrayFormated
}

export const getStatusCode = ( state ) => {
  return state.statusCode
}