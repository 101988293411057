import laroaccessApi from "@/api/laroaccessApi"

export const loadDevices= async ({ commit }) => {
  try {
    const { status, data }= await laroaccessApi.get('/device',{
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    if ( !data ) {
      commit('setDevices', [])
      return
    }
    const devices = []
    //Transformar un objeto literal en un array de objetos
    for (let id of Object.keys( data )) {
      devices.push({
        ...data[id]
      })
    }
    commit('setDevices', devices)
    commit('setStatusCode', {code:status})
  } catch (error) {
    console.log(error)
  }
}
export const createDevice = async ({ commit }, device) => {
  try {
    const {name, sn, campus, direction, access_zone, check_attendance, uncheck_attendance } = device
    let checkAttendanceToSring = check_attendance.toString()
    let uncheckAttendance = uncheck_attendance.toString()
    const dataToSave = { name, sn, campus, direction, access_zone, check_attendance:checkAttendanceToSring, uncheck_attendance:uncheckAttendance }
    const { status } = await laroaccessApi.post('/device', dataToSave, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('setStatusCode', {code:status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}
export const updateDevice = async ({ commit }, data) => {
  const {id, value} = data
  try {
    const {name, sn, campus, direction, access_zone, check_attendance, uncheck_attendance } = value
    let checkAttendanceToSring = check_attendance.toString()
    let uncheckAttendance = uncheck_attendance.toString()
    const dataToSave = { name, sn, campus, direction, access_zone, check_attendance:checkAttendanceToSring, uncheck_attendance:uncheckAttendance }
    const { status } = await laroaccessApi.patch(`/device/${id}`, dataToSave, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('setStatusCode', {code: status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}

export const deleteDevice = async ({ commit }, idDevice) => {
  try {
    await laroaccessApi.delete(`/device/${idDevice}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('deleteDevice', idDevice)
  } catch (error) {
    console.log(error)
  }
}
