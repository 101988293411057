// export const myMutation = ( state ) => {

// }

export const setEmpresas = ( state, empresas ) => {
  state.empresas = [...empresas]
  state.isLoading = false
}

export const addEmpresa = ( state, empresas ) => {
  state.empresas = [empresas,...state.empresas]
  state.isLoading = false
}

export const deleteEmpresa= ( state, id ) => {
  state.empresas = state.empresas.filter( e => e._id !== id)
}

export const setStatusCode = ( state, status) => {
  state.statusCode = status
}