// export const myMutation = ( state ) => {

// }

export const setCategorias = ( state, categorias ) => {
  // state.marcajes = [...marcajes]
  state.categorias = [...categorias]
  state.isLoading = false
}
export const addCategoria = ( state, categoria ) => {
  state.categorias = [categoria,...state.categorias]
  state.isLoading = false
}
export const deleteCategoria = ( state, id ) => {
  state.categorias = state.categorias.filter( e => e._id !== id)
}
export const setStatusCode = ( state, status) => {
  state.statusCode = status
}