// export const myGetter = ( state ) => {
//   return state.algo
// }

export const getSedes= ( state ) => {
  return state.sedes
}

export const getSedeById = ( state ) => ( id = '' ) => {
  const sede = state.sedes.find(c => c._id === id)
  if (!sede) return
  return sede
}

export const getSedesNameValue = ( state ) => {
  const nuevoArray = state.sedes.map((objeto) => {
    return Object.keys(objeto).reduce((resultado, key) => {
      if (key === "name" || key === "_id") {
        resultado[key] = objeto[key];
      }
      return resultado;
    }, {});
  });
  const ArrayFormated = nuevoArray.map( sede => {
    return {
      label : sede.name,
      value : sede._id
    }
  }) 
  return ArrayFormated
}

export const getStatusCode = ( state ) => {
  return state.statusCode
}