// export const myGetter = ( state ) => {
//   return state.algo
// }
import "moment-timezone"
import moment from "moment"
const formDate = (date) => {
  const year = new Date(date).getFullYear();
  const month = new Date(date).getMonth() + 1;
  const day = new Date(date).getDate();
  return `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
}
export const getVehicles = ( state ) => {
  return state.vehicles
}

export const getVehiclesProcesado = ( state ) => {
  const arrayProcesado = state.vehicles.map((item) => ({
    _id: item._id,
    marca: item.fabricator,
    placa: item.plate,
    modelo: item.model?.toString() || 'Sin modelo',
    tipo: item.type || 'Sin tipo',
    color: item.color || 'Sin color',
    descripcion: item.description,
    barcode: item.barcode,
    contract_start_date:  item.contract_start_date ? formDate(item.contract_start_date) : 'Sin fecha',
    contract_end_date: formDate(item.contract_end_date),
    is_active: item.is_active,
    last_activity: item.last_activity?moment(item.last_activity).tz('America/Bogota').locale('es').fromNow():null
  }));
  return arrayProcesado;
}

export const getVehicleById = ( state ) => ( id = '' ) => {
  const vehicle = state.vehicles.find(c => c._id === id)
  if (!vehicle) return
  return vehicle
}
export const getVehiculosActivos = ( state ) => {
  const arrayFormateado = state.vehicles.filter( vehicle => vehicle.is_active )
  return arrayFormateado.length
}
export const getVehiculosInacActivos = ( state ) => {
  const arrayFormateado = state.vehicles.filter( vehicle => !vehicle.is_active )
  return arrayFormateado.length
}
export const getVehiclesNameValue = ( state ) => {
  const nuevoArray = state.vehicles.map((objeto) => {
    return Object.keys(objeto).reduce((resultado, key) => {
      if (key === "name" || key === "_id") {
        resultado[key] = objeto[key];
      }
      return resultado;
    }, {});
  });

  const ArrayFormated = nuevoArray.map( vehicle => {
    return {
      label : vehicle.name,
      value : vehicle._id
    }
  }) 

  return ArrayFormated
}

export const getStatusCode = ( state ) => {
  return state.statusCode
}