import { createRouter,createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/modules/login/views/LoginView.vue'),
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "adminLayout" */ '@/views/AdminView.vue'),
    children: [
      {
        path: '/dashboardlayout',
        name: 'dashboardlayout',
        component: () => import(/* webpackChunkName: "dashboardlayout" */ '@/modules/dashboard/layouts/DashboardLayout.vue'),
        children: [
          {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import(/* webpackChunkName: "dashboardMain" */ '@/modules/dashboard/views/DashboardMain.vue'),
          },
          {
            path: '/dashboard_vehiculos',
            name: 'dashboard-vehiculos',
            component: () => import(/* webpackChunkName: "dashboardMain" */ '@/modules/dashboard/views/DashboardVehiculos.vue'),
          },
          {
            path: '/dashboard_visitantes',
            name: 'dashboard-visitantes',
            component: () => import(/* webpackChunkName: "dashboardMain" */ '@/modules/dashboard/views/DashboardVisitantes.vue'),
          }
        ]
      },
      {
        path: '/empleadoslayout',
        name: 'empleadoslayout',
        component: () => import(/* webpackChunkName: "empleadosLayout" */ '@/modules/empleados/layouts/EmpleadosLayout.vue'),
        children: [
          {
            path: '/empleados',
            name: 'empleados',
            component: () => import(/* webpackChunkName: "empleados" */ '@/modules/empleados/views/HomeEmpleados.vue')
          },
          {
            path: '/file_empleado/:id',
            name: 'file-empleado',
            component: () => import(/* webpackChunkName: "file-empleado" */ '@/modules/empleados/views/FileEmpleado.vue'),
            props: (route) => {
              return {
                id: route.params.id
              }
            }
          }
        ]
      },
      {
        path: '/empresaslayout',
        name: 'empresaslayout',
        component: () => import(/* webpackChunkName: "empresasLayout" */ '@/modules/empresas/layouts/EmpresasLayout.vue'),
        children: [
          {
            path: '/home_empresas',
            name: 'home-empresas',
            component: () => import(/* webpackChunkName: "home-empresas" */ '@/modules/empresas/views/HomeEmpresas.vue'),
          },
          {
            path: '/file_empresa/:id',
            name: 'file-empresa',
            component: () => import(/* webpackChunkName: "file-empresas" */ '@/modules/empresas/views/FileEmpresa.vue'),
            props: (route) => {
              return {
                id: route.params.id
              }
            }
          }
        ]
      },
      {
        path: '/terminaleslayout',
        name: 'terminaleslayout',
        component: () => import(/* webpackChunkName: "terminaleslayout" */ '@/modules/terminales/layouts/TerminalesLayout.vue'),
        children: [
          {
            path: '/home_terminales',
            name: 'home-terminales',
            component: () => import(/* webpackChunkName: "home-terminales" */ '@/modules/terminales/views/HomeTerminales.vue'),
          },
          {
            path: '/file_terminal/:id',
            name: 'file-terminal',
            component: () => import(/* webpackChunkName: "file-terminal" */ '@/modules/terminales/views/FileTerminal.vue'),
            props: (route) => {
              return {
                id: route.params.id
              }
            }
          }
        ]
      },
      {
        path: '/categoriaslayout',
        name: 'categoriaslayout',
        component: () => import(/* webpackChunkName: "categoriaslayout" */ '@/modules/categorias/layouts/CategoriasLayout.vue'),
        children: [
          {
            path: '/home_categorias',
            name: 'home-categorias',
            component: () => import(/* webpackChunkName: "home-categorias" */ '@/modules/categorias/views/HomeCategorias.vue'),
          },
          {
            path: '/file_categoria/:id',
            name: 'file-categoria',
            component: () => import(/* webpackChunkName: "file-categoria" */ '@/modules/categorias/views/FileCategoria.vue'),
            props: (route) => {
              return {
                id: route.params.id
              }
            }
          }
        ]
      },
      {
        path: '/zonaslayout',
        name: 'zonaslayout',
        component: () => import(/* webpackChunkName: "zonaslayout" */ '@/modules/zonas/layouts/ZonasLayout.vue'),
        children: [
          {
            path: '/home_zonas',
            name: 'home-zonas',
            component: () => import(/* webpackChunkName: "home-zonas" */ '@/modules/zonas/views/HomeZonas.vue'),
          },
          {
            path: '/file_zona/:id',
            name: 'file-zona',
            component: () => import(/* webpackChunkName: "file-zona" */ '@/modules/zonas/views/FileZona.vue'),
            props: (route) => {
              return {
                id: route.params.id
              }
            }
          }
        ]
      },
      {
        path: '/gruposlayout',
        name: 'gruposlayout',
        component: () => import(/* webpackChunkName: "gruposLayout" */ '@/modules/grupos/layouts/GruposLayout.vue'),
        children: [
          {
            path: '/home_grupos',
            name: 'home-grupos',
            component: () => import(/* webpackChunkName: "home-grupos" */ '@/modules/grupos/views/HomeGrupos.vue'),
          },
          {
            path: '/file_grupo/:id',
            name: 'file-grupo',
            component: () => import(/* webpackChunkName: "file-grupo" */ '@/modules/grupos/views/FileGrupo.vue'),
            props: (route) => {
              return {
                id: route.params.id
              }
            }
          }
        ]
      },
      {
        path: '/usuarioslayout',
        name: 'usuarioslayout',
        component: () => import(/* webpackChunkName: "usuariosLayout" */ '@/modules/usuarios/layouts/UsuariosLayout.vue'),
        children: [
          {
            path: '/home_usuarios',
            name: 'home-usuarios',
            component: () => import(/* webpackChunkName: "home-usuarios" */ '@/modules/usuarios/views/HomeUsuarios.vue'),
          }
        ]
      },
      {
        path: '/sedeslayout',
        name: 'sedeslayout',
        component: () => import(/* webpackChunkName: "sedeslayout" */ '@/modules/sedes/layouts/SedesLayout.vue'),
        children: [
          {
            path: '/home_sedes',
            name: 'home-sedes',
            component: () => import(/* webpackChunkName: "home-sedes" */ '@/modules/sedes/views/HomeSedes.vue'),
          },
          {
            path: '/file_sede/:id',
            name: 'file-sede',
            component: () => import(/* webpackChunkName: "file-sede" */ '@/modules/sedes/views/FileSede.vue'),
            props: (route) => {
              return {
                id: route.params.id
              }
            }
          }
        ]
      },
      {
        path: '/visitanteslayout',
        name: 'visitanteslayout',
        component: () => import(/* webpackChunkName: "visitanteslayout" */ '@/modules/visitantes/layouts/VisitantesLayout.vue'),
        children: [
          {
            path: '/home_visitantes',
            name: 'home-visitantes',
            component: () => import(/* webpackChunkName: "home-visitantes" */ '@/modules/visitantes/views/HomeVisitantes.vue'),
          },
          {
            path: '/file_visitante',
            name: 'file-visitante',
            component: () => import(/* webpackChunkName: "file-visitante" */ '@/modules/visitantes/views/FileVisitante.vue'),
          }
        ]
      },
      {
        path: '/vehiculoslayout',
        name: 'vehiculoslayout',
        component: () => import(/* webpackChunkName: "vehiculoslayout" */ '@/modules/vehiculos/layouts/VehiculosLayout.vue'),
        children: [
          {
            path: '/home_vehiculos',
            name: 'home-vehiculos',
            component: () => import(/* webpackChunkName: "home-vehiculos" */ '@/modules/vehiculos/views/HomeVehiculos.vue'),
          },
          {
            path: '/file_vehiculo/:id',
            name: 'file-vehiculo',
            component: () => import(/* webpackChunkName: "file-vehiculo" */ '@/modules/vehiculos/views/FileVehiculo.vue'),
            props: (route) => {
              return {
                id: route.params.id
              }
            }
          }
        ]
      },
      {
        path: '/marcajeslayout',
        name: 'marcajeslayout',
        component: () => import(/* webpackChunkName: "marcajeslayout" */ '@/modules/marcajes/layouts/MarcajesLayout.vue'),
        children: [
          {
            path: '/home_marcajes',
            name: 'home-marcajes',
            component: () => import(/* webpackChunkName: "home-marcajes" */ '@/modules/marcajes/views/HomeMarcajes.vue'),
          },
        ]
      },
      {
        path: '/miperfil',
        name: 'miperfil',
        component: () => import(/* webpackChunkName: "perfilLayout" */ '@/modules/perfil/layouts/PerfilLayout.vue'),
        children: [
          {
            path: '/home_perfil',
            name: 'home-perfil',
            component: () => import(/* webpackChunkName: "home-perfil" */ '@/modules/perfil/views/HomePerfil.vue'),
          },
        ]
      },
    ],
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/modules/login/views/LoginView.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
// Guardian de navegación para proteger las rutas
router.beforeEach((to, from, next) => {
  const publicPages = ['/login'] // Rutas públicas que no requieren token
  const authRequired = !publicPages.includes(to.path) // Requiere autenticación
  const token = JSON.parse(sessionStorage.getItem("_token"))
  if (authRequired && !token) {
    next('/login') // Redirige a la página de inicio de sesión si no se encuentra el token
  } else {
    next() // Continúa con la navegación si se encuentra el token o la ruta es pública
  }
})
export default router
