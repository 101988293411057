import laroaccessApi from "@/api/laroaccessApi"

export const loadCategorias = async ({ commit }) => {
  try {
    const { status, data }= await laroaccessApi.get('/category',{
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    if ( !data ) {
      commit('setCategorias', [])
      return
    }
    const categorias = []
    //Transformar un objeto literal en un array de objetos
    for (let id of Object.keys( data )) {
      categorias.push({
        ...data[id]
      })
    }
    commit('setCategorias', categorias)
    commit('setStatusCode', {code:status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}
export const createCategoria = async ({ commit }, categoria) => {
  try {
    const {name, sub_company } = categoria
    const dataToSave = { name, sub_company }
    const { status } = await laroaccessApi.post('/category', dataToSave, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('setStatusCode', {code:status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}
export const updateCategoria = async ({ commit }, data) => {
  const {id, value} = data
  try {
    const {name, sub_company } = value
    const dataToSave = { name, sub_company }
    const { status } = await laroaccessApi.patch(`/category/${id}`, dataToSave, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('setStatusCode', {code: status})
  } catch (error) {
    commit('setStatusCode', {code: error.response.data.statusCode, message:error.response.data.message})
  }
}

export const deleteCategoria = async ({ commit }, idCategoria) => {
  try {
    await laroaccessApi.delete(`/category/${idCategoria}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("_token")).token}`
      }
    })
    commit('deleteCategoria', idCategoria)
    
  } catch (error) {
    console.log(error)
  }
}
