// export const myMutation = ( state ) => {

// }

export const setSedes = ( state, sedes ) => {
  state.sedes = [...sedes]
  state.isLoading = false
}
export const addSede = ( state, sedes ) => {
  state.sedes = [sedes,...state.sedes]
  state.isLoading = false
}
export const deleteSede= ( state, id ) => {
  state.sedes = state.sedes.filter( e => e._id !== id)
}
export const setStatusCode = ( state, status) => {
  state.statusCode = status
}