<template  >
  <router-view />
</template>
<script>
import { mapActions, mapState } from 'vuex'
import NavBarVue from './components/NavBar.vue'

export default {
  components: {
    NavBarVue
  },
}
</script>
<style>

</style>