// export const myGetter = ( state ) => {
//   return state.algo
// }
import "moment-timezone"
import moment from "moment"
const formDate = (date) => {
  const year = new Date(date).getFullYear();
  const month = new Date(date).getMonth() + 1;
  const day = new Date(date).getDate();
  return `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
}
export const getEmpleados = ( state ) => {
  const arrayFormateado = state.empleados.map(empleado => ({
    ...empleado,
    categories: empleado.categories.map(categoria => categoria.name).join(', '),
  }))
  return arrayFormateado
}
export const getcountByCategory = (state) => {
  const count = state.empleados.reduce((acc, item) => {
    const categoryName = item.categories[0]?.name;
    if (!acc[categoryName]) {
      acc[categoryName] = { category: categoryName, registros: 0 };
    }
    acc[categoryName].registros++;
    return acc;
  }, {});

  return Object.values(count);
}
export const getEmpleadosProcesado = (state) => {
  const arrayProcesado = state.empleados.map((item) => ({
    _id: item._id,
    first_name: item.first_name,
    first_lastname: item.first_lastname,
    dni: item.dni,
    email: item.email,
    phone: item.phone,
    sub_company: item.campus.sub_company.name,
    categories: item.categories.map(categoria => categoria.name).join(', '),
    rfid: item.rfid,
    contract_start_date: formDate(item.contract_start_date),
    contract_end_date: formDate(item.contract_end_date),
    is_active: item.is_active,
    last_activity:  item.last_activity?moment(item.last_activity).tz('America/Bogota').locale('es').fromNow():null
  }));
  return arrayProcesado;
}
export const getEmpleadosActivos = ( state ) => {
  const arrayFormateado = state.empleados.filter( empleado => empleado.is_active )
  return arrayFormateado.length
}
export const getEmpleadosInacActivos = ( state ) => {
  const arrayFormateado = state.empleados.filter( empleado => !empleado.is_active )
  return arrayFormateado.length
}
export const getEmpleadoById = ( state ) => ( id = '' ) => {
  const empleado = state.empleados.find(c => c._id === id)
  if (!empleado) return
  return empleado
}

export const getStatusCode = ( state ) => {
  return state.statusCode
}